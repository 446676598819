export const updateStocksFetch = async (results) => {
    try {
        if (results === undefined) {
            throw new Error("Invalid parameters");
        }

        const sessions_id = localStorage.getItem("session_id");
        const response = await fetch("https://api.pemont-insight.com/collect/stocks", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessions_id,
            },
            body: JSON.stringify({"data": results}),
        });

        if (response.status === 401) {
            throw new Error("Unauthorized");
        }

        return response.json();
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}