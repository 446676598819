import React, { useState, useEffect } from 'react';
import { Container, Table, Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { update_sales_data } from '../api/CollectSales';
import { collectStocksFetch } from '../api/CollectStocks';
import { retrieveAllSessions } from '../api/RetrieveSessions';
import { RetrieveCollectStockStatus } from '../api/RetrieveCollectStockStatus';
import { updateStocksFetch } from '../api/UpdateStocks';
import { retrieveCodeSetting } from '../api/RetrieveCodeSetting';
import { updateCodeSetting, convertToCSV } from '../api/UpdateCodeSetting';
import { downloadSalesData } from '../api/DownloadSalesData';
import PlatformsEnum from '../constants/PlatformsEnum';
import { redirectSessionOut } from '../api/Auth';
import { useNavigate } from 'react-router-dom';
import { downloadStocksData } from '../api/DownloadStocksData';
import './SettingsPage.css';

function Settings() {
  const [productCodes, setProductCodes] = useState([]);
  const [logs, setLogs] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editRecordIndex, setEditRecordIndex] = useState(null);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [salesUpdating, setSalesUpdating] = useState(false);
  const [stocks, setStocks] = useState(null);
  const [stocksUpdating, setStocksUpdating] = useState(false);
  const [productCodeUpdateLoading, setProductCodeUpdateLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 코드 세팅 데이터를 가져옵니다.
    const retrieveCodeSettingData = async () => {
      try {
        const response = await retrieveCodeSetting();
        setProductCodes(response);
        setLoading(false);
      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        addLog(err.message);
      }
    };

    // 컴포넌트가 마운트될 때 코드 세팅 데이터를 가져옵니다.
    retrieveCodeSettingData();
  }, [navigate]); // 빈 배열은 컴포넌트가 처음 마운트될 때 한 번만 실행됨.

  useEffect(() => {
    // 크롬 확장프로그램 메시지 리스너 등록
    window.addEventListener("message", (event) => {
      if (event.data.type === "extension_response") {
        if (event.data.data.status === "success") {
          updateStocksFetch(event.data.data.results).then((_) => {
            if (event.data.data.results) {
              setStocks(event.data.data.results);
            } else {
              alert("수집 된 데이터가 없습니다.");
            }
            setStocksUpdating(false);
          });
        }
      } else if (event.data.type === "extension_log") {
        // if object type is Object, parse json
        if (typeof event.data.data === 'object') {
          addLog(JSON.stringify(event.data.data));
        } else if (event.data.data === undefined) {
          console.log("undefined value");
        } else if (event.data.data instanceof Error) {
          console.log("Error");
          addLog(event.data.data.message);
        } else {
          addLog(event.data.data);
        }
      }
    });
  }, []);

  // 로그 메시지를 추가합니다.
  const addLog = (message) => {
    if (message !== undefined && message !== null && message !== '') {
      setLogs(prevLogs => `${prevLogs}\n${message}`);
    }
  };

  // 현재 테이블에서 데이터를 취득해서 성형한후 API로 전송
  const updateCodeSettingData = async () => {
    setProductCodeUpdateLoading(true);
    await updateCodeSetting(convertToCSV(productCodes));
    setProductCodeUpdateLoading(false);
  };

  // 현재 선택중인 날짜를 기준으로 매출데이터를 다운로드
  const downloadSales = async () => {
    try {
      await downloadSalesData(startDate, endDate);
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
    }
  };

  // 현재 선택중인 플랫폼의 재고데이터를 다운로드
  const downloadStocks = async () => {
    const selectedStockPlatforms = getSelectedStockPlatforms();
    const sessions = await retrieveAllSessions();
    const selectedSessions = sessions.filter(session => selectedStockPlatforms.some(platform => platform.id === session.platform_id));

    try {
      downloadStocksData(selectedSessions);
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
    }
  };

  // 모든 체크박스를 해제합니다.
  const uncheckAll = (name, event) => {
    const checkboxes = document.querySelectorAll(`input[name="${name}"]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    event.target.checked = false;
  };

  // 테이블의 필드를 클릭했을 때 수정할 수 있도록 처리합니다.
  const handleFieldClick = (recordIndex, field) => {
    setEditRecordIndex(recordIndex);
    setEditField(field);
    setEditValue(productCodes[recordIndex][field]);
  };

  // 수정된 필드의 값을 저장합니다.
  const handleFieldChange = (event) => {
    setEditValue(event.target.value);
  };

  // 수정된 필드의 값을 저장하고 수정 상태를 해제합니다.
  const handleFieldBlur = () => {
    const updatedProductCodes = [...productCodes];
    updatedProductCodes[editRecordIndex][editField] = editValue;
    setProductCodes(updatedProductCodes);
    setEditRecordIndex(null);
    setEditField(null);
  };

  // 행을 추가합니다.
  const addRow = () => {
    const newRow = {
      no: productCodes.length + 1,
      code: '',
      name: `New Product ${productCodes.length + 1}`,
      localCost: '',
      currencyRate: '',
      MUSHINSA: '',
      CAFE24: '',
      W_CONCEPT: '',
      OCO: '',
      CM29: '',
      EQL: '',
      KREAM: '',
      CAFE24_PRT: '',
    };
    setProductCodes([...productCodes, newRow]);
  };

  // 행을 삭제합니다.
  const deleteRow = (index) => {
    const updatedProductCodes = productCodes.filter((_, i) => i !== index);
    const renumberedProductCodes = updatedProductCodes.map((product, i) => ({
      ...product,
      no: i + 1,
    }));
    setProductCodes(renumberedProductCodes);
  };

  // 선택된 플랫폼을 가져옵니다.
  const getSelectedStockPlatforms = () => {
    const checkboxes = document.querySelectorAll('input[name="stock-platforms"]:checked');
    const selectedPlatforms = [];

    checkboxes.forEach(checkbox => {
      const label = checkbox.nextSibling.textContent;
      const matchingPlatform = Object.values(PlatformsEnum).find(platform => platform.displayName === label);

      if (matchingPlatform) {
        selectedPlatforms.push(matchingPlatform);
      }
    });

    return selectedPlatforms;
  };

  // 선택된 플랫폼을 가져옵니다.
  const getSelectedSalesPlatforms = () => {
    const checkboxes = document.querySelectorAll('input[name="sales-platforms"]:checked');
    const selectedPlatforms = [];

    checkboxes.forEach(checkbox => {
      const label = checkbox.nextSibling.textContent;
      const matchingPlatform = Object.values(PlatformsEnum).find(platform => platform.displayName === label);

      if (matchingPlatform) {
        selectedPlatforms.push(matchingPlatform);
      }
    });

    return selectedPlatforms;
  };

  // 매출 데이터를 업데이트합니다.
  const updateSales = async () => {
    try {
      setSalesUpdating(true);
      const sessions = await retrieveAllSessions();
      const selectedSalesPlatforms = getSelectedSalesPlatforms();
      if (startDate === "" || endDate === "") {
        alert("날짜를 입력해주세요.");
        return;
      }
      const response = await update_sales_data(startDate, endDate, selectedSalesPlatforms, sessions, addLog);
      addLog(`${JSON.stringify(response)}`);
      setSalesUpdating(false);
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
    }
  }

  // 재고 데이터를 업데이트합니다.
  const updateStock = async () => {
    try {
      setStocksUpdating(true);
      setStocks(null);
      const sessions = await retrieveAllSessions();
      const selectedSalesPlatforms = getSelectedSalesPlatforms();
      await collectStocksFetch(sessions, selectedSalesPlatforms);

    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      } else if (err.message === 'Invalid parameters') {
        alert("플랫폼 로그인 정보를 찾을 수 없습니다.");
        setStocksUpdating(false);
        return;
      }
      addLog(err.message);
    }
  }

  const fields = [
    'code', 'name', 'localCost', 'currencyRate',
    'MUSHINSA', 'CAFE24', 'W_CONCEPT', 'OCO', 'CM29',
    'EQL', 'KREAM', 'CAFE24_PRT'
  ];

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <div className="settings-page">
      <Container>
        <h1 className="my-4">Settings</h1>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Chrome Extension</Card.Title>
            <Table striped bordered hover className="text-left">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Stocks</span>
                      <div>
                        {stocksUpdating && <Spinner animation="border" role="status" />}
                        <Button variant="success" className="ml-2 m-2" onClick={updateStock}>Update</Button>
                        <Button variant="info" className="ml-2" onClick={downloadStocks}>Excel Download</Button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group className="d-inline-block">
                      {Object.keys(PlatformsEnum).map((platform) => (
                        <Form.Check inline type="checkbox" name="stock-platforms" label={PlatformsEnum[platform].displayName} key={platform} defaultChecked />
                      ))}
                      <Form.Check inline type="checkbox" label="체크해제" onClick={(event) => uncheckAll('stock-platforms', event)} />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="text-left">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Sales</span>
                      <div>
                        {salesUpdating && <Spinner animation="border" role="status" />}
                        <Button variant="success" className="ml-2 m-2" onClick={updateSales}>Update</Button>
                        <Button variant="info" className="ml-2" onClick={downloadSales}>Excel Download</Button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group className="d-inline-block">
                      {Object.keys(PlatformsEnum).map((platform) => (
                        <Form.Check inline type="checkbox" name="sales-platforms" label={PlatformsEnum[platform].displayName} key={platform} defaultChecked />
                      ))}
                      <Form.Check inline type="checkbox" label="체크해제" onClick={(event) => uncheckAll('sales-platforms', event)} />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group as={Row}>
                      <Col xs={6}>
                        <Form.Label>Start Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>End Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>무신사 매출은 CAFE24에서 연동됩니다.</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>API Logs</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                <Button variant="danger" onClick={() => setLogs('')}>Clear</Button>
              </Col>
            </Row>
            <textarea
              className="form-control"
              rows="10"
              value={logs}
              readOnly
            />
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px' }}>
            <Card.Title>Product Codes</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                {productCodeUpdateLoading && <Spinner animation="border" role="status" />}
                <Button variant="primary" className="m-2" onClick={addRow}>Add Row</Button>
                <Button variant="success" onClick={updateCodeSettingData}>Update</Button>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead className="text-center">
                <tr>
                  <th>No.</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Local Cost</th>
                  <th>Currency Rate</th>
                  <th>MUSHINSA</th>
                  <th>CAFE24</th>
                  <th>W_CONCEPT</th>
                  <th>OCO</th>
                  <th>29CM</th>
                  <th>EQL</th>
                  <th>KREAM</th>
                  <th>CAFE24_PRT</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {productCodes.map((product, index) => (
                  <tr key={index}>
                    <td>{product.no}</td>
                    {fields.map((field) => (
                      <td key={field} onClick={() => handleFieldClick(index, field)}>
                        {editRecordIndex === index && editField === field ? (
                          <input
                            type="text"
                            value={editValue}
                            onChange={handleFieldChange}
                            onBlur={handleFieldBlur}
                            autoFocus
                            className="wild-input"
                          />
                        ) : (
                          product[field]
                        )}
                      </td>
                    ))}
                    <td>
                      <Button variant="danger" onClick={() => deleteRow(index)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
export default Settings;